import React, {Component} from 'react'
import {ApolloConsumer} from '@apollo/react-components'
import styled from 'styled-components'
import {Button, Modal, Form, Input, Descriptions} from 'antd'
import {gql} from 'apollo-boost'
import history from '../history'
import {PREFIX} from "../config";

const MUTATION = gql`
    mutation createGuide($input:NewGuide!) {
        createGuide(input:$input)
        {
            id
            name
            inhaleTime
            isPredefined
            holdTime
            pauseTime
            exhaleTime
            createdAt
        }
    }
`

const ModalContainer = styled.div`
  .ant-row{
      &.ant-form-item{
          margin-right:10px;
      }
  } 
`

const initState = {
  showModal: false,
  loading: false,
  duration: 0,
  rate: 0,
  inhaleTime: 0,
  holdTime: 0,
  exhaleTime: 0,
  pauseTime: 0,
}

class addFormBreathGuides extends Component {
  state = initState

  render() {
    const {getFieldDecorator} = this.props.form
    let {inhaleTime, holdTime, pauseTime, exhaleTime} = this.state
    if (inhaleTime) {
      inhaleTime = parseFloat(`${inhaleTime}`)
    } else inhaleTime = 0
    if (holdTime) {
      holdTime = parseFloat(`${holdTime}`)
    } else holdTime = 0
    if (pauseTime) {
      pauseTime = parseFloat(`${pauseTime}`)
    } else pauseTime = 0
    if (exhaleTime) {
      exhaleTime = parseFloat(`${exhaleTime}`)
    } else exhaleTime = 0
    let duration = inhaleTime + holdTime + pauseTime + exhaleTime
    let rate=0
    if (duration) {
      rate = parseFloat(`${60 / duration}`).toFixed(2)
    } 
    return (
        <ApolloConsumer>
          {
            (client) => (

                <React.Fragment>
                  <Button onClick={() => {
                    this.setState({
                      showModal: true,
                    })
                  }

                  } size={'small'} style={{marginBottom: 20}}>Add new</Button>
                  <Modal
                      okText={'Save'}
                      okButtonProps={{loading: this.state.loading}}
                      onCancel={() => {
                        this.setState({
                          showModal: false,
                        })
                      }}
                      title={'Set Breath Guide'}
                      visible={this.state.showModal}
                      onOk={() => {
                        this.props.form.validateFields((err, values) => {
                          if (!err) {
                            this.setState({
                                  loading: true,
                                },
                                () => {
                                  client.mutate({
                                    mutation: MUTATION,
                                    variables: {
                                      input: values,
                                    },
                                  }).then((res) => {
                                    this.props.form.resetFields()
                                    this.setState({
                                          ...initState,
                                          loading: false,
                                          showModal: false,
                                        },
                                        () => {
                                          if (this.props.onCompleted) {
                                            this.props.onCompleted(client,
                                                res.data.createGuide)
                                          }
                                        },
                                    )
                                  })
                                },
                            )
                          }
                        })
                        history.push(`${PREFIX}/breath-guides`)
                      }}
                  >
                    <ModalContainer>
                      <Form
                          layout={'horizontal'}
                          onSubmit={(e) => {
                            e.preventDefault()
                          }}>
                        <Form.Item label={'Guide name'}>
                          {
                            getFieldDecorator(
                                'name',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Guide name is required',
                                    },
                                  ],
                                },
                            )(<Input/>)
                          }
                        </Form.Item>
                        <Descriptions column={2}>
                          <Descriptions.Item
                              label={'Breath cycle duration (sec)'}>
                            {duration}
                          </Descriptions.Item>
                          <Descriptions.Item label='Breath rate (br/min)'>
                            {rate}
                          </Descriptions.Item>
                        </Descriptions>
                        <div style={{display: 'flex'}}>
                          <Form.Item label={'Inhale (sec)'}>
                            {
                              getFieldDecorator(
                                  'inhaleTime',
                              )(<Input
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      this.setState({
                                        inhaleTime: e.target.value,
                                      })
                                    } else {
                                      this.setState({
                                        inhaleTime: 0,
                                      })
                                    }
                                  }}
                              />)
                            }
                          </Form.Item>
                          <Form.Item label={'Hold (sec)'}>
                            {
                              getFieldDecorator(
                                  'holdTime',
                              )(<Input
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      this.setState({
                                        holdTime: e.target.value,
                                      })
                                    } else {
                                      this.setState({
                                        holdTime: 0,
                                      })
                                    }

                                  }}
                              />)
                            }
                          </Form.Item>
                          <Form.Item label={'Exhale (sec)'}>
                            {
                              getFieldDecorator(
                                  'exhaleTime',
                              )(<Input
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      this.setState({
                                        exhaleTime: e.target.value,
                                      })
                                    } else {
                                      this.setState({
                                        exhaleTime: 0,
                                      })
                                    }

                                  }}
                              />)
                            }
                          </Form.Item>
                          <Form.Item label={'Pause (sec)'}>
                            {
                              getFieldDecorator(
                                  'pauseTime',
                              )(<Input
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      this.setState({
                                        pauseTime: e.target.value,
                                      })
                                    } else {
                                      this.setState({
                                        pauseTime: 0,
                                      })
                                    }

                                  }}
                              />)
                            }
                          </Form.Item>
                        </div>
                      </Form>
                    </ModalContainer>
                  </Modal>
                </React.Fragment>

            )
          }
        </ApolloConsumer>
    )
  }
}

export default Form.create({name: 'formGuide'})(addFormBreathGuides)