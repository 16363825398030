import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { routes } from "./router";
import { ApolloProvider } from "@apollo/react-hooks";
import { apolloClient } from "./client";
import history from "./history";
import { AppContext } from "./context";
import RenderRouter from "./components/RenderRouter";
import { gql } from "apollo-boost";
import { setAppToken } from "./helper";
import { Spin } from "antd";
import styled from "styled-components";
import {PREFIX} from "./config";

const SpinContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const ME_QUERY = gql`
  query me {
    me {
      id
      firstName
      lastName
      namePrefix
      nameSuffix
      phone
      email
      username
      roles
    }
  }
`;

class App extends React.Component {
  setCurrentUser = value => {
    this.setState(
      {
        currentUser: value
      },
      () => {
        if (value) {
          localStorage.setItem("appUser", JSON.stringify(value));
        } else {
          localStorage.removeItem("appUser");
        }
      }
    );
  };

  state = {
    currentUser: null,
    setCurrentUser: this.setCurrentUser,
    loading: true
  };

  getCurrentUser = () => {
    // get user from server
    apolloClient
      .query({
        query: ME_QUERY
      })
      .then(res => {
        this.setState(
          {
            loading: false
          },
          () => this.setCurrentUser(res.data.me)
        );
      })
      .catch(() => {
        this.setState({ loading: false }, () => {
          this.setCurrentUser(null);
          setAppToken(null);
          // redirect to login
        });
      });
  };

  componentDidMount() {
    this.getCurrentUser();
  }

  render() {
    return this.state.loading ? (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    ) : (
      <ApolloProvider client={apolloClient}>
        <AppContext.Provider value={this.state}>
          <Router history={history}>
            <Switch>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={`${PREFIX}${route.path}`}
                    component={RenderRouter(route)}
                  />
                );
              })}
            </Switch>
          </Router>
        </AppContext.Provider>
      </ApolloProvider>
    );
  }
}

export default App;
