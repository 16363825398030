import React, {Component} from 'react'
import {Button, Card, Form, Input, notification} from 'antd'
import {Mutation} from '@apollo/react-components'
import {gql} from 'apollo-boost'
import styled from 'styled-components'

const MUTATION = gql`
    mutation requestForgotPassword($input: RequestResetPassword!){
        requestResetPassword(input: $input)
    }
`

const Container = styled.div`
display: flex;
   justify-content: center;
  .ant-card{
    width: 320px;
    @media(min-width: 768px){
      width: 400px;
    }
    .ant-card-head-title{
      text-align: center;
    }
  }
  
`

class RequestForgotPasswordForm extends Component {
  state = {
    loading: false,
    completed: false,
  }

  render() {
    const {getFieldDecorator} = this.props.form

    return (
        <Mutation
            onCompleted={() => {
              this.setState({loading: false, completed: true})
            }}
            onError={(err) => {
              notification.error({
                message: err.toLocaleString().
                    replace('Error: GraphQL error:', ''),
              })
              this.setState({loading: false})
            }}
            mutation={MUTATION}>
          {(requestResetPassword) => (
              <Container>
                <Card title={'Forgot Password?'}>
                  {this.state.completed ? (
                      <p>If the email you specified exists in our system, we've
                        sent a password reset link to it.</p>
                  ) : (
                      <Form onSubmit={(e) => {
                        e.preventDefault()
                        this.props.form.validateFields((err, values) => {
                          if (!err) {
                            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                values.username)) {
                              values.email = values.username
                              delete values.username
                            }
                            this.setState({
                              loading: true,
                            }, () => {
                              requestResetPassword({variables: {input: values}})
                            })
                          }

                        })
                      }}>
                        <div>
                          <p>Enter your account email address or username and we
                            will
                            send you a link to reset your password.</p>
                        </div>
                        <Form.Item label={'Email or username'}>
                          {
                            getFieldDecorator('username', {
                              initialValue: '',
                              rules: [
                                {
                                  required: true,
                                  message: 'Email or username is required.',
                                },
                              ],
                            })(<Input/>)
                          }
                        </Form.Item>
                        <Form.Item style={{textAlign: 'center'}}>
                          <Button
                              style={{width: '100%'}}
                              loading={this.state.loading}
                              htmlType="submit"
                              type="primary">
                            Request password reset</Button>
                        </Form.Item>
                      </Form>
                  )
                  }

                </Card>
              </Container>
          )}
        </Mutation>
    )
  }
}

export default Form.create({name: 'form'})(RequestForgotPasswordForm)
