import React, {Component} from 'react'
import {Button, Modal, Form, Input, Descriptions, notification} from 'antd'
import styled from 'styled-components'
import {gql} from 'apollo-boost'
import {ApolloConsumer} from '@apollo/react-components'

const MUTATION = gql`
    mutation updateGuide($input: UpdateGuide!){
        updateGuide(input: $input){
            id
            name
            inhaleTime
            isPredefined
            holdTime
            pauseTime
            exhaleTime
            createdAt
        }
    }
`

const ModalContainer = styled.div`
  .ant-row{
      &.ant-form-item{
          margin-right:10px;
      }
  } 
`

class EditBreathGuides extends Component {
  state = {
    showModal: false,
    loading: false,
    duration: 0,
    rate: 0,
    inhaleTime: this.props.data.inhaleTime,
    holdTime: this.props.data.holdTime,
    exhaleTime: this.props.data.exhaleTime,
    pauseTime: this.props.data.pauseTime,
  }

  render() {

    const {getFieldDecorator} = this.props.form
    let {inhaleTime, holdTime, pauseTime, exhaleTime} = this.state
    if (inhaleTime) {
      inhaleTime = parseFloat(`${inhaleTime}`)
    } else inhaleTime = 0
    if (holdTime) {
      holdTime = parseFloat(`${holdTime}`)
    } else holdTime = 0
    if (pauseTime) {
      pauseTime = parseFloat(`${pauseTime}`)
    } else pauseTime = 0
    if (exhaleTime) {
      exhaleTime = parseFloat(`${exhaleTime}`)
    } else exhaleTime = 0
    // let duration=inhaleTime+holdTime+pauseTime+exhaleTime
    let duration = pauseTime + exhaleTime + holdTime + inhaleTime
    let rate = 0
    if (duration) {
      rate = parseFloat(`${60 / duration}`).toFixed(2)
    }

    // const dataDuration=this.state.pauseTime+this.state.exhaleTime+this.state.holdTime+this.state.inhaleTime
    // const dataRate=parseFloat(`${60/dataDuration}`)
    //if(dataDuration){
    //    duration=dataDuration
    //    rate=dataRate.toFixed(2)
    // }

    return (
        <ApolloConsumer>
          {
            (client) => (
                <React.Fragment>
                  <Button style={{marginBottom: 20}} onClick={() => {
                    this.setState({
                      showModal: true,
                    })
                  }} size={'small'}>Edit</Button>
                  <Modal
                      title="Edit Breath Guide"
                      visible={this.state.showModal}
                      okText={'Save'}
                      onCancel={() => {
                        this.setState({
                          showModal: false,
                        })
                      }}
                      okButtonProps={{loading: this.state.loading}}
                      onOk={
                        () => {
                          this.props.form.validateFields((err, values) => {
                            if (!err) {

                              this.setState({
                                    loading: true,
                                  },
                                  () => {
                                    client.mutate({
                                      mutation: MUTATION,
                                      variables: {
                                        input: {
                                          id: this.props.data.id,
                                          name: values.name,
                                          exhaleTime: values.exhaleTime,
                                          inhaleTime: values.inhaleTime,
                                          holdTime: values.holdTime,
                                          pauseTime: values.pauseTime,
                                        },
                                      },

                                    }).then((res) => {
                                      this.setState({
                                            loading: false,
                                            showModal: false,
                                          },
                                          () => {
                                            if (this.props.onCompleted) {
                                              this.props.onCompleted(client, res.data.updateGuide)
                                            }
                                          },
                                      )
                                    }).catch((e) => {
                                      this.setState({
                                        loading: false,
                                      })
                                      notification.error(
                                          {message: e.toString()})
                                    })
                                  },
                              )

                            }
                          })
                        }
                      }
                  ><ModalContainer>
                    <Form
                        layout={'horizontal'}
                        onSubmit={(e) => {
                          e.preventDefault()
                        }}>
                      <Form.Item label={'Guide name'}>
                        {
                          getFieldDecorator(
                              'name',
                              {
                                initialValue: this.props.data.name,
                                rules: [
                                  {
                                    required: true,
                                    message: 'Guide name is required',
                                  },
                                ],
                              },
                          )(<Input/>)
                        }
                      </Form.Item>
                      <Descriptions column={2}>
                        <Descriptions.Item
                            label={'Breath cycle duration (sec)'}>
                          {duration}
                        </Descriptions.Item>
                        <Descriptions.Item label='Breath rate (br/min)'>
                          {rate}
                        </Descriptions.Item>
                      </Descriptions>
                      <div style={{display: 'flex'}}>
                        <Form.Item label={'Inhale (sec)'}>
                          {
                            getFieldDecorator(
                                'inhaleTime',
                                {
                                  initialValue: this.state.inhaleTime,
                                },
                            )(<Input
                                onChange={(e) => {
                                  if (e.target.value) {
                                    this.setState({
                                      inhaleTime: e.target.value,
                                    })
                                  } else {
                                    this.setState({
                                      inhaleTime: 0,
                                    })
                                  }
                                }}
                            />)
                          }
                        </Form.Item>
                        <Form.Item label={'Hold (sec)'}>
                          {
                            getFieldDecorator(
                                'holdTime',
                                {
                                  initialValue: this.state.holdTime,
                                },
                            )(<Input
                                onChange={(e) => {
                                  if (e.target.value) {
                                    this.setState({
                                      holdTime: e.target.value,
                                    })
                                  } else {
                                    this.setState({
                                      holdTime: 0,
                                    })
                                  }

                                }}
                            />)
                          }
                        </Form.Item>
                        <Form.Item label={'Exhale (sec)'}>
                          {
                            getFieldDecorator(
                                'exhaleTime',
                                {
                                  initialValue: this.state.exhaleTime,
                                },
                            )(<Input
                                onChange={(e) => {
                                  if (e.target.value) {
                                    this.setState({
                                      exhaleTime: e.target.value,
                                    })
                                  } else {
                                    this.setState({
                                      exhaleTime: 0,
                                    })
                                  }

                                }}
                            />)
                          }
                        </Form.Item>
                        <Form.Item label={'Pause (sec)'}>
                          {
                            getFieldDecorator(
                                'pauseTime',
                                {
                                  initialValue: this.state.pauseTime,
                                },
                            )(<Input
                                onChange={(e) => {
                                  if (e.target.value) {
                                    this.setState({
                                      pauseTime: e.target.value,
                                    })
                                  } else {
                                    this.setState({
                                      pauseTime: 0,
                                    })
                                  }

                                }}
                            />)
                          }
                        </Form.Item>
                      </div>
                    </Form>
                  </ModalContainer>
                  </Modal>
                </React.Fragment>

            )
          }
        </ApolloConsumer>

    )
  }
}

export default Form.create({name: 'form'})(EditBreathGuides)