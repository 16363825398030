import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {gql} from 'apollo-boost'
import {Query} from '@apollo/react-components'
import {Button, Descriptions, Tag} from 'antd'
import history from '../history'
import UserEmailEditable from './UserEmailEditable'
import {PREFIX} from "../config";

const Q = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            firstName
            lastName
            username
            email
            isPasswordExpired
            address
            lastAccessed
            status
            enabledProcedures {
                procedureId
                name
                isEnabled
                createdAt
            }
            client {
                id
                caregiverRegistrationNumber
                gender
                dateOfBirth
                heathNotes
                preferredBreathRate
                audioType
                audioGuide
                audioFile
                disclaimerAccepted
                lastTested
            }
        }
    }
`

class ClientProfile extends Component {
  render() {
    const {id} = this.props

    return (
        <Query query={Q} variables={{id: id}}>
          {({data, error, loading, client}) => {
            if (error) return <div>{error.toString()}</div>
            if (loading) return <div>Loading...</div>
            const user = data.user
            return (
                <div>
                  <h2 className={'page-title'}>Client profile</h2>
                  <Descriptions column={2}>
                    <Descriptions.Item
                        label="Full name">{`${user.firstName} ${user.lastName}`}</Descriptions.Item>
                    <Descriptions.Item label="Contact email">
                      <UserEmailEditable
                          onChange={(value) => {

                            const variable = {
                              id,
                            }
                            const cache = client.readQuery({
                              query: Q,
                              variables: variable,
                            })

                            client.writeQuery({
                              query: Q,
                              variables: variable,
                              data: {
                                ...cache,
                                user: {
                                  ...cache.user,
                                  email: value,
                                },

                              },
                            })
                          }}
                          userId={user.id}
                          defaultValue={user.email}/>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Gender">{user.client.gender}</Descriptions.Item>
                    <Descriptions.Item
                        label="Date of birth">{user.client.dateOfBirth}</Descriptions.Item>
                    <Descriptions.Item
                        label="Address">{user.address}</Descriptions.Item>
                    <Descriptions.Item
                        label="Username">{user.username}</Descriptions.Item>
                    <Descriptions.Item
                        label="Account status">{user.status}</Descriptions.Item>
                    <Descriptions.Item
                        label="Password status">{user.isPasswordExpired ?
                        'Expired' :
                        'Not expired'}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label={'Enabled procedures'}>
                      {user.enabledProcedures.map((p, index) => {
                        if (p.isEnabled) {
                          return <span key={index}
                                       style={{marginRight: 5}}><Tag>{`${p.name}`}</Tag></span>
                        }
                        return null
                      })}
                    </Descriptions.Item>
                  </Descriptions>

                  <Button onClick={() => {
                    if (this.props.destination) {
                      history.push(`${PREFIX}${this.props.destination}`)
                    } else {
                      history.push(`${PREFIX}/clients`)
                    }
                  }}>Back</Button>
                </div>
            )
          }}
        </Query>
    )
  }
}

ClientProfile.propTypes = {
  id: PropTypes.any,
  destination: PropTypes.any,
}

export default ClientProfile
