import React, {useState} from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CHECK_EXIST_USERS_QUERY, SYNC_USER_MUTATION, USER_RECORD_QUERY} from "../graphql/query";
import {Alert, Button, Modal, Table} from "antd";

const CheckList = ({record, onClose}) => {
  const [error, setError] = useState(null);
  const users = [
    record.user,
    ...record.relationUsers || []
  ]
  const {data, loading} = useQuery(CHECK_EXIST_USERS_QUERY, {
    variables: {
      users: users.map(user => ({
        email: user.email ? user.email : null,
        username: user.username ? user.username : null,
      }))
    },
    fetchPolicy: 'network-only',
    context: {
      clientName: 'sra',
    },
  });

  const [syncMutate, {loading: syncLoading}] = useMutation(SYNC_USER_MUTATION, {
    context: {
      clientName: 'sra',
    },
  });

  const handleSync = async () => {
    setError(null)
    try {
      await syncMutate({
        variables: {
          payload: record,
        },
        context: {
          clientName: 'sra',
        }
      })
      onClose();
    } catch (e) {
      setError(e.toString())
    }
  }
  if (loading) return 'Loading...';
  const existingUsers = data?.checkExistingUsers || [];
  const columns = [
    {
      title: 'User type',
      dataIndex: 'userType',
      key: 'userType',
    },
    {
      title: "Name",
      render: (text, row) => <span>{`${row.firstName} ${row.lastName}`}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      "title": "Username",
      "dataIndex": "username",
    },
    {
      title: "Check existing user",
      render: (text, row, index) => <>{existingUsers[index] ?
        <span style={{
          color: 'red',
          fontWeight: 'bold'
        }}>Yes</span> :
        <span style={{
          color: 'green',
          fontWeight: 'bold'
        }}>No</span>
      }</>,
    }
  ]

  // if all users are existing users then disable sync button
  const disableSync = existingUsers.every(exist => exist);
  return (
    <div>
      <p>
        Are you sure you want to sync the following users to SRA?
      </p>
      <Table
        bordered={true}
        columns={columns}
        dataSource={users}
        pagination={false}
        rowKey="id"
      />
      {error &&
        (
          <div style={{
            marginTop: 20,
          }}>
            <Alert message={error} type="error" showIcon/>
          </div>
        )}
      <div style={{
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
        flex: 1,

      }}>
        <Button onClick={onClose} type={"default"}>Cancel</Button>
        <Button onClick={handleSync} loading={syncLoading} disabled={disableSync || loading}
                type={"primary"}>Sync</Button>
      </div>
    </div>
  )
};
const SyncUserModal = ({user, visible, onClose}) => {
  const {data, loading} = useQuery(USER_RECORD_QUERY, {
    variables: {id: user.id}
  })
  return (
    <Modal
      confirmLoading={loading}
      // fullScreen
      width={'80%'}
      title={
        data?.userRecord ? `Sync user ${data?.userRecord?.user.firstName} ${data?.userRecord?.user.lastName}? to SRA` : ''
      }
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
      footer={null}
    >
      {loading ? 'Loading...' : <CheckList onClose={onClose} record={data?.userRecord}/>}
    </Modal>
  );
};

export default SyncUserModal;
