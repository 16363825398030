import {Button} from "antd";
import {useState} from "react";
import SyncUserModal from "./SyncUserModal";

const SyncUserButton = ({user}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setShowModal(true);
        }}
      >
        SRA
      </Button>
      {showModal && (
        <SyncUserModal
          user={user}
          visible={showModal}
          onClose={() => {
            setShowModal(false);
          }}/>
      )}
    </>
  );
};

export default SyncUserButton;
