import React, {Component} from 'react'
import styled from 'styled-components'
import {Input, Table} from 'antd'
import gql from 'graphql-tag'
import {Query} from '@apollo/react-components'
import EditBreathGuides from './EditBreathGuides'
import AddFormBreathGuides from './AddFormBreathGuides'
import DeleteBreathGuide from './DeleteBreathGuide'

const QUERY = gql`
    query guides($filter:FindFilter) {
        guides(filter:$filter) {
            total
            nodes {
                id
                name
                inhaleTime
                isPredefined
                holdTime
                pauseTime
                exhaleTime
                createdAt
            }
        }
    }
`

const Container = styled.div`
    .page-header{
        .page-title{
            text-align:center;
            font-weight:bold;
        }
        .search-container{
            text-align:right;
            margin-bottom:20px;
            .ant-input-search{
                display:inline-block;
                width:inherit;
            }
        }
    }
    .page-content{
        .actions{
            display:flex;
            button{
                margin-right:10px;
            }
        }
    }
    .page-footer{
        margin-top:30px;
        text-align:center;
    }

`

class BreathGuides extends Component {
  state = {
    currentPage: 0,
    filter: {
      search: '',
      limit: 10,
      offset: 0,
      isPredefined: true,
    },
  }

  render() {
    const {filter, currentPage} = this.state
    const variables = {filter}
    const columns = [
      {
        title: 'Guide name',
        dataIndex: 'name',
        key: 'name',

      },
      {
        title: 'Rate',
        dataIndex: 'Rate',
        key: 'Rate',
        render: (_, row) => {
          let duration = row.inhaleTime + row.holdTime + row.exhaleTime +
              row.pauseTime
          if (duration === 0) {
            return 'N/A'
          }
          return parseFloat(`${60 / duration}`).toFixed(2)
        },
      },
      {
        title: 'Inhale',
        dataIndex: 'inhaleTime',
        key: 'inhaleTime',
      },
      {
        title: 'Hold',
        dataIndex: 'holdTime',
        key: 'holdTime',
      },
      {
        title: 'Exhale',
        dataIndex: 'exhaleTime',
        key: 'exhaleTime',
      },
      {
        title: 'Pause',
        dataIndex: 'pauseTime',
        key: 'pauseTime',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, row) => {
          return (
              <div className={'actions'}>
                <EditBreathGuides onCompleted={
                  (client, data) => {
                    const cache = client.readQuery({
                      query: QUERY,
                      variables: variables,
                    })

                    client.writeQuery({
                      query: QUERY,
                      variables: filter,
                      data: {
                        ...cache,
                        guides: {
                          ...cache.guides,
                          nodes: cache.guides.nodes.map((node) => {
                                if (node.id === data.id) {
                                  return data
                                }
                                return node
                              },
                          ),

                        },

                      },
                    })
                  }
                } data={row}/>
                <DeleteBreathGuide
                    onCompleted={(client) => {
                      try {
                        const cache = client.readQuery({
                          query: QUERY,
                          variables: variables,
                        })
                        client.writeQuery({
                          query: QUERY,
                          variables: variables,
                          data: {
                            ...cache,
                            guides: {
                              ...cache.guides,
                              total: cache.guides.total - 1,
                              nodes: cache.guides.nodes.filter(
                                  (n) => n.id !== row.id),
                            },

                          },
                        })

                      } catch (e) {
                        console.log('Error:', e)
                      }
                    }}
                    data={row}
                />
              </div>
          )
        },
      },

    ]
    return (
        <Container>
          <div className={'page-header'}>
            <h2 className={'page-title'}>Manage Breath Guides</h2>
            <div className={'search-container'}>
              <Input.Search
                  onChange={(e) => {
                    this.setState({
                      filter: {
                        ...filter,
                        search: e.target.value,
                      },
                    })
                  }}
                  value={filter.search}
                  placeholder={'Search...'}
              />
            </div>
            <AddFormBreathGuides
                onCompleted={(client, data) => {
                  const cache = client.readQuery({
                    query: QUERY,
                    variables: variables,
                  })

                  client.writeQuery({
                    query: QUERY,
                    variables: variables,
                    data: {
                      ...cache,
                      guides: {
                        ...cache.guides,
                        total: cache.guides.total + 1,
                        nodes: [...cache.guides.nodes, data],
                      },
                    },

                  })
                }}/>

          </div>
          <div className={'page-content'}>
            <Query query={QUERY} variables={{filter: filter}}>
              {
                ({error, loading, data}) => {
                  if (error) return <div>{error.toString()}</div>
                  if (loading) return <div>Loading...</div>
                  return (
                      <Table
                          rowKey={'id'}
                          dataSource={data.guides.nodes}
                          columns={columns}
                          pagination={{
                            current: currentPage,
                            pageSize: this.state.filter.limit,
                            total: data.guides.total,
                            onChange: (page) => {
                              this.setState({
                                currentPage: page,
                                filter: {
                                  ...filter,
                                  offset: (page - 1) * filter.limit,
                                },
                              })
                            },
                          }}
                      />
                  )
                }
              }
            </Query>
          </div>
        </Container>
    )
  }
}

export default BreathGuides