import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {setContext} from 'apollo-link-context'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {getAppToken} from './helper'
import {API, SYNC_TARGET_API} from "./config";
import {ApolloLink} from "apollo-boost";

const httpLink = createHttpLink({
  uri: `${API}/query`,
})

const targetHttpLink = createHttpLink({
  uri: `${SYNC_TARGET_API}/query`,
})

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  let token = ''
  const tokenObject = getAppToken()
  if (tokenObject) {
    token = tokenObject.id
    //@todo handle check token expired
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const linkWithTarget = ApolloLink.split(
  operation => operation.getContext().clientName === 'sra',
  targetHttpLink,
  httpLink,
)

export const apolloClient = new ApolloClient({
  link: authLink.concat(SYNC_TARGET_API ? linkWithTarget : httpLink),
  cache: new InMemoryCache(),
})

