import React, {Component} from 'react'
import Clients from '../components/Clients'

class ClientsPage extends Component {
  render() {
    return (
        <Clients/>
    )
  }
}

export default ClientsPage
