import React, {Component} from 'react'
import {AppContext} from '../context'
import {Dropdown, Avatar, Menu, Icon} from 'antd'
import {setAppToken} from '../helper'
import history from '../history'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'
import {PREFIX, SETTING} from "../config";

const Container = styled.div`
  display: flex;
  padding: 0 20px;
  .app-title{
    flex-grow: 1;
    font-weight: bold;
    font-size: 18px;
    rgba(0, 0, 0, 0.85);
    @media(min-width: 768px){
      font-size: 24px;
    }
    h1{
      margin: 0;
      padding: 0;
    }
  }
  .logout-btn{
    margin-left: 10px;
  }
  .current-user{
    font-weight: bold;
  }
  .user-avatar{
    height: 62px;
    .ant-avatar{
      cursor: pointer;
    }
  }
`

class AppHeader extends Component {

  render() {
    const {isAnonymous} = this.props
    return (
        <Container>
          <div className={'app-title'}>
            <div>
              <span onClick={() => {
                history.push(`${PREFIX}/`)
              }}>{SETTING.title}</span>
            </div>
          </div>

          {
            !isAnonymous && (
                <div className={'header-sidebar'}>
                  <ApolloConsumer>
                    {(client) => (
                        <AppContext.Consumer>
                          {({currentUser, setCurrentUser}) => (
                              <div>
                                {currentUser && (
                                    <div>
                                      <Dropdown trigger={['click']} overlay={(
                                          <Menu>
                                            <Menu.Item>
                                              <Link to={`${PREFIX}/profile/edit`}>
                                                <Icon
                                                    type={'user'}/> {`${currentUser.firstName} ${currentUser.lastName}`}
                                              </Link>
                                            </Menu.Item>
                                            {currentUser.roles.includes(
                                                'Administrator') && (
                                                <Menu.Item>
                                                  <Link to={`${PREFIX}/`}>
                                                    <Icon
                                                        type="dashboard"/> Admin
                                                    dashboard
                                                  </Link>
                                                </Menu.Item>
                                            )}
                                            <Menu.Item onClick={async () => {
                                              await client.mutate({
                                                mutation: gql`mutation logout{logout}`,
                                              })

                                              setCurrentUser(null)
                                              // logout token
                                              setAppToken(null)
                                              // redirect to login
                                              client.resetStore()

                                              history.push(`${PREFIX}/login`)


                                            }}>
                                              <Icon type="logout"/> Logout
                                            </Menu.Item>
                                          </Menu>
                                      )}>
                                        <div className="user-avatar">
                                          <Avatar size={40}>{currentUser.firstName.charAt(
                                              0)}</Avatar>
                                        </div>
                                      </Dropdown>

                                    </div>
                                )}
                              </div>
                          )}
                        </AppContext.Consumer>)}
                  </ApolloConsumer>
                </div>
            )
          }

        </Container>
    )
  }
}

export default AppHeader
