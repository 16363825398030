import React, {Component} from 'react'
import {Button, Card, Form, Input, notification, Switch} from 'antd'
import styled from 'styled-components'
import {gql} from 'apollo-boost'
import {Mutation} from '@apollo/react-components'

const MUTATION = gql`
    mutation updateUser($input: UpdateUser!) {
        updateUser(
            input: $input
        ) {
            id
            firstName
            lastName
            namePrefix
            nameSuffix
            phone
            email
            username
            roles
        }
    }
`

const Container = styled.div`
display: flex;
justify-content: center;
.ant-card{
  width: 320px;
  @media(min-width: 768px){
     width: 450px;
  }
}
    
`

class EditProfileForm extends Component {
  state = {
    loading: false,
    isChangePassword: false,
    confirmDirty: false,
  }
  validateEmail = (rule, value, callback) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      callback()
    } else {
      callback('Invalid email address')
    }

  }
  handleConfirmBlur = e => {
    const {value} = e.target
    this.setState({confirmDirty: this.state.confirmDirty || !!value})
  }

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!')
    } else {
      callback()
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true})
    }
    callback()
  }

  render() {
    const {currentUser} = this.props
    const {getFieldDecorator} = this.props.form
    if (!currentUser) {
      return null
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };


    return (
        <Container>
          <Card title={'Edit profile'}>
            <Mutation
                onCompleted={(res) => {
                  notification.success(
                      {message: 'Your profile has been saved!'})
                  this.setState({loading: false}, () => {
                    // update user store app context
                    if (this.props.onCompleted) {
                      this.props.onCompleted(res.updateUser)
                    }

                  })
                }}
                onError={(err) => {
                  notification.error({
                    message: err.toLocaleString().
                        replace('Error: GraphQL error:', ''),
                  })
                  this.setState({loading: false})
                }}
                mutation={MUTATION}>
              {(updateUser) => (
                  <Form {...formItemLayout} onSubmit={(e) => {
                    this.props.form.validateFields((err, values) => {
                      e.preventDefault()
                      if (!err) {
                        this.setState({
                          loading: true,
                        }, () => {

                          let data = {
                            id: currentUser.id,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            username: values.username,
                            email: values.email,
                          }
                          if (this.state.isChangePassword) {
                            data.newPassword = values.password
                          }
                          updateUser({
                            variables: {
                              input: data,
                            },
                          })
                        })
                      }
                    })
                  }}>
                    <Form.Item label={'First name'}>
                      {
                        getFieldDecorator('firstName', {
                          initialValue: currentUser.firstName,
                          rules: [
                            {
                              required: true,
                              message: 'First name is required.',
                            },
                          ],
                        })(<Input/>)
                      }
                    </Form.Item>
                    <Form.Item label={'Last name'}>
                      {
                        getFieldDecorator('lastName', {
                          initialValue: currentUser.lastName,
                          rules: [
                            {
                              required: true,
                              message: 'Last name is required.',
                            },
                          ],
                        })(<Input/>)
                      }
                    </Form.Item>
                    <Form.Item label={'Contact email'}>
                      {
                        getFieldDecorator('email', {
                          initialValue: currentUser.email,
                          rules: [
                            {
                              required: true,
                              message: 'Email is required.',
                            },
                            {
                              validator: this.validateEmail,
                            },
                          ],
                        })(<Input/>)
                      }
                    </Form.Item>
                    <Form.Item label={'Username'}>
                      {
                        getFieldDecorator('username', {
                          initialValue: currentUser.username,
                          rules: [
                            {
                              required: true,
                              message: 'Username is required.',
                            },
                          ],
                        })(<Input/>)
                      }
                    </Form.Item>

                    <Form.Item label={'Change password'}>
                      <Switch
                          style={{marginLeft: 10}}
                          onChange={(v) => {
                            this.setState({isChangePassword: v})
                          }}/>
                    </Form.Item>

                    {
                      this.state.isChangePassword && (
                          <React.Fragment>
                            <Form.Item label={'New password'}>
                              {
                                getFieldDecorator('password', {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'New password is required.',
                                    },
                                    {
                                      validator: this.validateToNextPassword,
                                    },
                                  ],
                                })(<Input.Password/>)
                              }
                            </Form.Item>

                            <Form.Item label={'Confirm password'}>
                              {
                                getFieldDecorator('confirm', {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Confirm password is required.',
                                    },
                                    {
                                      validator: this.compareToFirstPassword,
                                    },
                                  ],
                                })(<Input.Password/>)
                              }
                            </Form.Item>
                          </React.Fragment>
                      )
                    }


                    <Form.Item
                        {...tailFormItemLayout}
                        style={{textAlign: 'center'}}>
                      <Button
                          loading={this.state.loading}
                          size={'large'}
                          style={{width: '100%'}}
                          htmlType={'submit'} type={'primary'}>Save</Button>
                    </Form.Item>
                  </Form>
              )}
            </Mutation>
          </Card>
        </Container>
    )
  }
}

export default Form.create({name: 'form'})(EditProfileForm)