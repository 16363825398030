import  {useContext} from 'react';
import {AppContext} from "../context";

const AuthComponent = ({children, allowRoles}) => {
  const {currentUser} = useContext(AppContext);
  if (!currentUser || !allowRoles || !currentUser.roles) {
    return null
  }
  if (allowRoles.some(r => currentUser.roles.includes(r))) {
    return children
  }
  return null
};

export default AuthComponent;
