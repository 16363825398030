import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import styled from "styled-components";

const MUTATION = gql`
  mutation resetPassword($input: ResetPassword!) {
    resetPassword(input: $input)
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  .ant-card {
    width: 320px;
    @media (min-width: 768px) {
      width: 400px;
    }
    .ant-card-head-title {
      text-align: center;
    }
  }
`;

class PasswordResetForm extends Component {
  state = {
    loading: false,
    completed: false,
    confirmDirty: false
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { token } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Mutation
        onError={e => {
          notification.error({
            message: e.toLocaleString().replace("Error: GraphQL error:", "")
          });
          this.setState({
            loading: false
          });
        }}
        onCompleted={() => {
          this.setState({ loading: false, completed: true }, () => {
            notification.success({
              message: "You successfully reset your password"
            });
          });
        }}
        mutation={MUTATION}
      >
        {resetPassword => (
          <Container>
            <Card title={"Reset password"}>
              {!this.state.completed ? (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.props.form.validateFields((err, values) => {
                      if (!err) {
                        this.setState({ loading: true }, () => {
                          resetPassword({
                            variables: {
                              input: {
                                newPassword: values.password,
                                token: token
                              }
                            }
                          });
                        });
                      }
                    });
                  }}
                >
                  <Form.Item label={"New password"}>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "New password is required."
                        },
                        {
                          validator: this.validateToNextPassword
                        }
                      ]
                    })(<Input.Password />)}
                  </Form.Item>
                  <Form.Item label={"Confirm password"}>
                    {getFieldDecorator("confirm", {
                      rules: [
                        {
                          required: true,
                          message: "Confirm password is required."
                        },
                        {
                          validator: this.compareToFirstPassword
                        }
                      ]
                    })(<Input.Password />)}
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      style={{ width: "100%" }}
                      loading={this.state.loading}
                      htmlType="submit"
                      type="primary"
                    >
                      Change password
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <div>
                  <p>You successfully reset your password.</p>
                </div>
              )}
            </Card>
          </Container>
        )}
      </Mutation>
    );
  }
}

PasswordResetForm.propTypes = {
  token: PropTypes.string
};

export default Form.create({ name: "form" })(PasswordResetForm);
