import React from 'react'
import MainLayout from '../layout/MainLayout'
import history from '../history'
import {PREFIX} from "../config";

export default function(route) {
  class RenderRouter extends React.Component {
    constructor(props) {
      super(props);

      if (!route.anonymous) {
        const value = localStorage.getItem('appUser')
        if (!value) {
          history.push(`${PREFIX}/login`)
        }
      }

    }

    render() {
      const ComposedComponent = route.component
      return route.useLayout ? (
          <MainLayout
              isHideSidebar={route.hideSidebar}
              isAnonymous={route.anonymous}
              contentBackground={route.background ? route.background : '#FFF'}>
            <ComposedComponent {...this.props}/>
          </MainLayout>
      ) : <ComposedComponent {...this.props}/>
    }
  }

  return RenderRouter
}