import React, { Component } from 'react';
import BreathGuides from '../components/BreathGuides'
class BreathGuidesPage extends Component {
    render() {
        return (
            <BreathGuides />
        );
    }
}

export default BreathGuidesPage;