import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Icon, Input, AutoComplete} from 'antd'
import {gql} from 'apollo-boost'
import {Query} from '@apollo/react-components'

const {Option} = AutoComplete

const Q = gql`
    query clients($filter: FindFilter){
        clients(filter: $filter){
            total
            nodes{
                id
                firstName
                lastName
                username
                client{
                    id
                }
            }
        }
    }
`

class SearchClientAutocomplete extends Component {

  state = {
    selected: null,
    filter: {
      search: '',
      limit: 20,
      offset: 0,
    },
  }

  render() {
    const {filter} = this.state
    const {placeholder} = this.props
    return (
        <div>
          <Query query={Q} variables={{filter}}>
            {({data}) => {
              let dataSource = []
              if (data && data.clients && data.clients.nodes.length) {
                dataSource = data.clients.nodes
              }
              return (
                  <AutoComplete
                      className="certain-category-search"
                      dropdownClassName="certain-category-search-dropdown"
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{width: 300}}
                      style={{width: '100%'}}
                      dataSource={dataSource.map((user, index) => (
                          <Option key={index} value={user.id}
                                  title={`${user.firstName}`}>
                            {`${user.firstName} ${user.lastName} - ${user.username}`}
                          </Option>
                      ))}
                      onSelect={(value) => {
                        const user = dataSource.find(
                            (u) => u.id === value)
                        this.setState({
                          selected: user,
                        }, () => {
                          if (this.props.onChange) {
                            this.props.onChange(user)
                          }
                        })
                      }}
                      onSearch={(s) => {
                        this.setState({
                          filter: {
                            ...filter,
                            search: s,
                          },
                        })
                      }}
                      placeholder={placeholder ?
                          placeholder :
                          'Search client...'}
                      optionLabelProp="value"
                  >
                    <Input suffix={<Icon
                        type="search"
                        className="certain-category-icon"/>}/>
                  </AutoComplete>
              )
            }}
          </Query>
        </div>
    )
  }
}

SearchClientAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default SearchClientAutocomplete
