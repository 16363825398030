import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, Modal, notification, Select} from 'antd'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'

const MUTATION = gql`
    mutation enableUserProcedures($input: UpdateEnableProcedures!) {
        enableUserProcedures(
            input: $input
        )
    }
`

class SetEnabledProcedures extends Component {
  state = {
    showModal: false,
    value: null,
  }

  render() {
    const {user} = this.props
    const defaultValue = []
    let options = []
    user.enabledProcedures.forEach((item) => {
      if (item.isEnabled) {
        defaultValue.push(item.procedureId)
      }
      options.push({
        label: item.name,
        value: item.procedureId,
      })
    })

    return (
        <ApolloConsumer>
          {(client) => (
              <React.Fragment>
                <Button
                    size="small"
                    onClick={() => {
                      this.setState(
                          {showModal: true})
                    }}>Proc</Button>
                {
                  this.state.showModal && (
                      <Modal
                          okButtonProps={{disabled: !this.state.value}}
                          onOk={() => {
                            const value = this.state.value
                            client.mutate({
                              mutation: MUTATION,
                              variables: {
                                input: {
                                  userId: user.id,
                                  procedures: this.state.value.map((item) => {
                                    return {
                                      procedureId: item,
                                      isEnabled: true,
                                    }
                                  }),
                                },
                              },
                            }).then(() => {

                              try {
                                const cache = client.readQuery({
                                  query: this.props.query,
                                  variables: this.props.variables,
                                })
                                let nodes = []
                                if (this.props.isCaregiver) {
                                  nodes = cache.caregivers.nodes
                                } else {
                                  nodes = cache.clients.nodes
                                }
                                nodes = nodes.map((u) => {
                                  if (u.id === user.id) {
                                    return {
                                      ...u,
                                      enabledProcedures: u.enabledProcedures.map(
                                          (item) => {
                                            if (value.includes(
                                                item.procedureId)) {
                                              item.isEnabled = true
                                            } else {
                                              item.isEnabled = false
                                            }
                                          }),
                                    }
                                  }
                                  return u

                                })

                                client.writeQuery({
                                  query: this.props.query,
                                  variables: this.props.variables,
                                  data: {
                                    ...cache,
                                    [this.props.isCaregiver ?
                                        'caregivers' :
                                        'clients']: {
                                      ...this.props.isCaregiver ?
                                          cache.caregivers :
                                          cache.clients,
                                      nodes: nodes,
                                    },
                                  },
                                })

                              } catch (e) {
                              }
                            }).catch((e) => {
                              notification.error({message: e.toString()})
                            })
                            this.setState({
                              value: [],
                              showModal: false,
                            })
                          }}
                          onCancel={() => this.setState({showModal: false})}
                          title={'Set enabled procedures'}
                          visible={this.state.showModal}>

                        <Checkbox.Group
                            className={'enabled-procedures'}
                            options={options}
                            defaultValue={defaultValue}
                            onChange={(value) => {
                              this.setState({value})
                            }}/>
                      </Modal>
                  )
                }
              </React.Fragment>
          )}
        </ApolloConsumer>
    )
  }
}

SetEnabledProcedures.propTypes = {
  user: PropTypes.object,
  variables: PropTypes.object,
  query: PropTypes.any,
  isCaregiver: PropTypes.bool,
}

export default SetEnabledProcedures
