import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Select,
  notification,
  Checkbox
} from "antd";
import moment from "moment";

const QUERY_CAREGIVER_BY_INVITE_TOKEN = gql`
  query caregiverByInviteToken($token: String!) {
    info: caregiverInfoByInviteToken(inviteToken: $token) {
      businessName
      inviteEmail
    }
  }
`;

const MUTATION_CREATE_USER = gql`
  mutation register($input: NewUser!) {
    register(input: $input) {
      id
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  .page-title {
    text-align: center;
  }
  .card-container {
    width: 320px;
    margin-top: 20px;
    @media (min-width: 768px) {
      width: 500px;
    }
  }
`;

class JoinCaregiverForm extends Component {
  state = {
    confirmDirty: false,
    loading: false,
    completed: false
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  validateEmail = (rule, value, callback) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      callback();
    } else {
      callback("Invalid email address");
    }
  };

  render() {
    const { token } = this.props;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 24,
          offset: 0
        }
      }
    };

    return (
      <Query query={QUERY_CAREGIVER_BY_INVITE_TOKEN} variables={{ token }}>
        {({ error, loading, data }) => {
          if (error)
            return (
              <div>
                {error.toLocaleString().replace("Error: GraphQL error:", "")}
              </div>
            );
          if (loading) return <div>Loading...</div>;

          return (
            <Container>
              <Card className={"card-container"}>
                <div className={"page-title"}>
                  <h2>Sign up to Body Health Analyzer</h2>
                </div>
                {!this.state.completed ? (
                  <Mutation
                    onError={e => {
                      notification.error({
                        message: e
                          .toLocaleString()
                          .replace("Error: GraphQL error:", "")
                      });
                      this.setState({
                        loading: false
                      });
                    }}
                    onCompleted={() => {
                      this.setState({
                        loading: false,
                        completed: true
                      });
                    }}
                    mutation={MUTATION_CREATE_USER}
                  >
                    {register => (
                      <Form
                        {...formItemLayout}
                        onClick={e => {
                          e.preventDefault();
                          this.props.form.validateFields((err, values) => {
                            if (!err) {
                              register({
                                variables: {
                                  input: {
                                    account: {
                                      firstName: values.firstName,
                                      lastName: values.lastName,
                                      email: values.email,
                                      username: values.username,
                                      password: values.password,
                                      phone: values.phone,
                                      userType: "Client",
                                      namePrefix: "",
                                      nameSuffix: "",
                                      language: "",
                                      measuringSystem: "",
                                      address: values.address
                                    },
                                    client: {
                                      caregiverInviteToken: token,
                                      acceptClient: values.acceptClient,
                                      gender: values.gender,
                                      dateOfBirth: values.dateOfBirth.format(
                                        "MM/DD/YYYY"
                                      ),
                                      healthNotes: "",
                                      preferredBreathRate: "",
                                      audioType: "Audio MIDI"
                                    }
                                  }
                                }
                              });
                            }
                          });
                        }}
                      >
                        <Form.Item label={"First name"}>
                          {getFieldDecorator("firstName", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "First name is required"
                              }
                            ]
                          })(<Input />)}
                        </Form.Item>
                        <Form.Item label={"Last name"}>
                          {getFieldDecorator("lastName", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Last name is required"
                              }
                            ]
                          })(<Input />)}
                        </Form.Item>

                        <Form.Item label={"Gender"}>
                          {getFieldDecorator("gender", {
                            initialValue: "Male",
                            rules: [
                              {
                                required: true,
                                message: "Gender is required"
                              }
                            ]
                          })(
                            <Select>
                              <Select.Option value={"Male"}>Male</Select.Option>
                              <Select.Option value={"Female"}>
                                Female
                              </Select.Option>
                              <Select.Option value={"Undefined"}>
                                Undefined
                              </Select.Option>
                            </Select>
                          )}
                        </Form.Item>

                        <Form.Item label={"Date of birth"}>
                          {getFieldDecorator("dateOfBirth", {
                            rules: [
                              {
                                required: true,
                                message: "Date of birth is required."
                              }
                            ]
                          })(
                            <DatePicker
                              disabledDate={d => !d || d.isAfter(moment())}
                              placeholder={"MM/DD/YYYY"}
                              format={"MM/DD/YYYY"}
                            />
                          )}
                        </Form.Item>

                        <Form.Item label={"Email"}>
                          {getFieldDecorator("email", {
                            initialValue: data.info.inviteEmail,
                            rules: [
                              {
                                required: true,
                                message: "Email is required"
                              },
                              {
                                validator: this.validateEmail
                              }
                            ]
                          })(<Input autoCapitalize={"off"} />)}
                        </Form.Item>

                        <Form.Item label={"Username"}>
                          {getFieldDecorator("username", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Username is required"
                              }
                            ]
                          })(<Input autoCapitalize={"off"} />)}
                        </Form.Item>
                        <Form.Item label={"Password"}>
                          {getFieldDecorator("password", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Password is required"
                              },
                              {
                                validator: this.validateToNextPassword
                              }
                            ]
                          })(<Input.Password />)}
                        </Form.Item>

                        <Form.Item label={"Confirm password"}>
                          {getFieldDecorator("confirm", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Password is required"
                              },
                              {
                                validator: this.compareToFirstPassword
                              }
                            ]
                          })(
                            <Input.Password onBlur={this.handleConfirmBlur} />
                          )}
                        </Form.Item>

                        <Form.Item label={"Contact phone"}>
                          {getFieldDecorator("phone", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Phone is required"
                              }
                            ]
                          })(<Input />)}
                        </Form.Item>
                        <Form.Item label={"Contact address"}>
                          {getFieldDecorator("address", {
                            initialValue: "",
                            rules: [
                              {
                                required: true,
                                message: "Address is required"
                              }
                            ]
                          })(<Input />)}
                        </Form.Item>
                        <Form.Item
                          labelCol={{ span: 0 }}
                          wrapperCol={{ span: 24 }}
                        >
                          {getFieldDecorator("acceptClient", {
                            valuePropName: "checked",
                            initialValue: true
                          })(
                            <Checkbox>
                              Share health data with the inviting caregiver
                            </Checkbox>
                          )}
                        </Form.Item>

                        <Form.Item
                          {...tailFormItemLayout}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            size="large"
                            loading={this.state.loading}
                            htmlType="submit"
                            style={{ width: "100%" }}
                            type="primary"
                          >
                            Sign up
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                  </Mutation>
                ) : (
                  <div>
                    <p style={{ textAlign: "center" }}>
                      Thank you for signing up to the service! Your new account
                      must be approved by the service administrator. It may take
                      some time. Once it is approved, you will receive a
                      notification email to finalize the sign-up process.
                    </p>
                  </div>
                )}
              </Card>
            </Container>
          );
        }}
      </Query>
    );
  }
}

JoinCaregiverForm.propTypes = {
  token: PropTypes.string
};

export default Form.create({ name: "form" })(JoinCaregiverForm);
