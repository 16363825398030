import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "@apollo/react-components";
import { gql } from "apollo-boost";
import styled from "styled-components";
import { Button, Card, notification } from "antd";

const QUERY_CAREGIVER_BY_INVITE_TOKEN = gql`
  query caregiverByInviteToken($token: String!) {
    info: caregiverInfoByInviteToken(inviteToken: $token) {
      businessName
      inviteEmail
      caregiverInfo {
        id
        firstName
        lastName
        namePrefix
        nameSuffix
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  .page-title {
    text-align: center;
  }
  .card-container {
    width: 320px;
    margin-top: 20px;
    @media (min-width: 768px) {
      width: 500px;
    }
  }
`;

class AcceptCaregiverInvite extends Component {
  state = {
    loading: false,
    completed: false
  };

  render() {
    const { token } = this.props;
    return (
      <Query query={QUERY_CAREGIVER_BY_INVITE_TOKEN} variables={{ token }}>
        {({ error, loading, data }) => {
          if (error)
            return (
              <div>
                {error.toLocaleString().replace("Error: GraphQL error:", "")}
              </div>
            );
          if (loading) return <div>Loading...</div>;
          const info = data.info.caregiverInfo;
          const caregiverName = `${info.namePrefix} ${info.firstName} ${info.lastName} ${info.nameSuffix}`;
          return (
            <Container>
              <Card className={"card-container"}>
                <div className={"page-title"}>
                  <h2>
                    {this.state.completed
                      ? "Sign up to Body Health Analyzer"
                      : `Accept an invitation to share data received from ${caregiverName.trim()}`}
                  </h2>
                </div>
                <div style={{ textAlign: "center" }}>
                  {!this.state.completed ? (
                    <Mutation
                      onError={e => {
                        notification.error({
                          message: e
                            .toLocaleString()
                            .replace("Error: GraphQL error:", "")
                        });
                        this.setState({ loading: false });
                      }}
                      onCompleted={() => {
                        this.setState({ loading: false, completed: true });
                      }}
                      mutation={gql`
                        mutation acceptCaregiverInvite($token: String!) {
                          acceptCaregiverInvite(token: $token)
                        }
                      `}
                    >
                      {acceptCaregiverInvite => (
                        <Button
                          loading={this.state.loading}
                          onClick={() => {
                            this.setState(
                              {
                                loading: true
                              },
                              () => {
                                acceptCaregiverInvite({
                                  variables: {
                                    token: token
                                  }
                                });
                              }
                            );
                          }}
                          size="large"
                          type="primary"
                        >
                          Accept
                        </Button>
                      )}
                    </Mutation>
                  ) : (
                    <p>Thank you for using BHA Cloud service!</p>
                  )}
                </div>
              </Card>
            </Container>
          );
        }}
      </Query>
    );
  }
}

AcceptCaregiverInvite.propTypes = {
  token: PropTypes.string
};

export default AcceptCaregiverInvite;
