import { gql } from "apollo-boost";

export const USER_RECORD_QUERY = gql`
  query UserRecord($id: ID!) {
    userRecord(id: $id)
  }
`;

export const CHECK_EXIST_USERS_QUERY = gql`
  query Query($users: [CheckExistingUserInput!]!) {
    checkExistingUsers(users: $users)
  }
`;

export const SYNC_USER_MUTATION = gql`
  mutation SyncUserRecord($payload: Any) {
    syncUserRecord(payload: $payload)
  }
`;

export const PROCEDURES_QUERY = gql`
  query procedures {
    procedures {
      id
      name
      createdAt
    }
  }
`;
