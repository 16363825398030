export const getAppToken = () => {
  const localStoreToken = localStorage.getItem('appToken')
  if (localStoreToken) {
    return JSON.parse(localStoreToken)
  }
  return null

}

export const setAppToken = (token) => {
  if (!token) {
    localStorage.removeItem('appToken')
  } else {
    localStorage.setItem('appToken', JSON.stringify(token))
  }

}

export const isAdministrator = (currentUser) => {
  if (currentUser && currentUser.roles) {
    return currentUser.roles.includes('Administrator')
  }
  return false
}

export const isObserver = (currentUser) => {
  if (currentUser && currentUser.roles) {
    return currentUser.roles.includes('Observer')
  }
  return false
}

export const ROLES = {
  Administrator: 'Administrator',
  Observer: 'Observer',
}
