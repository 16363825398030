import React, {Component} from 'react'
import LoginForm from '../components/LoginForm'

class LoginPage extends Component {
  render() {
    return <LoginForm/>
  }
}

export default LoginPage
