import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, notification} from 'antd'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'

const MUTATION = gql`
    mutation deleteData($id: ID!){
        deleteData(id: $id)
    }
`

class DeleteProcedureDataButton extends Component {
  state = {
    isToggle: false,
    loading: false,
  }

  render() {
    const {data} = this.props

    return (
        <ApolloConsumer>
          {(client) => (
              <React.Fragment>
                <Button size="small" onClick={() => {
                  this.setState({isToggle: !this.state.isToggle})
                }} type="danger">Delete</Button>

                <Modal
                    onCancel={() => {
                      this.setState({
                        isToggle: false,
                      })
                    }}
                    okText={'Delete'}
                    visible={this.state.isToggle}
                    okButtonProps={{loading: this.state.loading, type: "danger"}}
                    onOk={() => {
                      this.setState({loading: true}, () => {
                        client.mutate({
                          mutation: MUTATION,
                          variables: {id: data.id},
                        }).then(() => {
                          this.setState({loading: false, isToggle: false},
                              () => {
                                if (this.props.onCompleted) {
                                  this.props.onCompleted(client)
                                }
                              })
                        }).catch((e) => {
                          this.setState({loading: false, isToggle: false})
                          notification.error({message: e.toString()})
                        })
                      })
                    }}
                    title={'Delete procedure data confirmation'}>
                  <p>Are you sure want to delete procedure data?</p>
                </Modal>
              </React.Fragment>
          )}
        </ApolloConsumer>
    )
  }
}

DeleteProcedureDataButton.propTypes = {
  data: PropTypes.object,
  onCompleted: PropTypes.func,
}

export default DeleteProcedureDataButton
