import React, { Component } from "react";
import JoinCaregiverForm from "../../components/client/JoinCaregiverForm";

class RegisterPage extends Component {
  render() {
    const { match } = this.props;
    return <JoinCaregiverForm token={match.params.token} />;
  }
}

export default RegisterPage;
