import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, notification, Select} from 'antd'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'

const MUTATION = gql`
    mutation updateUser($input: UpdateUser!) {
        updateUser(
            input: $input
        ) {
            status
        }
    }
`

class ChangeAccountStatus extends Component {
  state = {
    showModal: false,
    value: '',
  }

  render() {
    const {user} = this.props
    const options = ['Active', 'Inactive', 'Locked', 'Closed']
    return (
        <ApolloConsumer>
          {(client) => (
              <React.Fragment>
                <Button
                    size={"small"}
                    onClick={() => {
                      this.setState(
                          {showModal: true})
                    }}>Status</Button>
                {
                  this.state.showModal && (
                      <Modal
                          okButtonProps={{disabled: !this.state.value}}
                          onOk={() => {
                            const value = this.state.value
                            client.mutate({
                              mutation: MUTATION,
                              variables: {
                                input: {
                                  id: user.id,
                                  status: value,
                                },
                              },
                            }).then(() => {

                              try {

                                const cache = client.readQuery({
                                  query: this.props.query,
                                  variables: this.props.variables,
                                })

                                let nodes = []
                                let updateKey = 'clients'
                                if (this.props.isCaregiver) {
                                  nodes = cache.caregivers.nodes
                                  updateKey = 'caregivers'
                                } else {
                                  nodes = cache.clients.nodes
                                }

                                nodes = nodes.map((u) => {
                                  if (u.id === user.id) {
                                    return {...u, status: value}
                                  }
                                  return u

                                })
                                client.writeQuery({
                                  query: this.props.query,
                                  variables: this.props.variables,
                                  data: {
                                    ...cache,
                                    [updateKey]: {
                                      ...this.props.isCaregiver ?
                                          cache.caregivers :
                                          cache.clients,
                                      nodes: nodes,
                                    },
                                  },
                                })

                              } catch (e) {
                              }
                            }).catch((e) => {
                              notification.error({message: e.toString()})
                            })
                            this.setState({
                              value: '',
                              showModal: false,
                            })
                          }}
                          onCancel={() => this.setState({showModal: false})}
                          title={'Change account status'}
                          visible={this.state.showModal}>
                        <Select
                            onChange={(value) => {
                              this.setState({
                                value,
                              })
                            }}
                            defaultValue={user.status}
                            style={{width: 150}}>
                          {options.map((option, index) => (
                              <Select.Option
                                  key={index}
                                  value={option}>{option}</Select.Option>
                          ))}
                        </Select>
                      </Modal>
                  )
                }
              </React.Fragment>
          )}
        </ApolloConsumer>
    )
  }
}

ChangeAccountStatus.propTypes = {
  user: PropTypes.object,
  variables: PropTypes.object,
  query: PropTypes.any,
  isCaregiver: PropTypes.bool,
}

export default ChangeAccountStatus
