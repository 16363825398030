import React, {Component} from 'react'
import ClientProfile from '../components/ClientProfile'
import queryString from 'query-string'
class ClientPage extends Component {
  render() {
    const {match: {params}} = this.props
    const values = queryString.parse(this.props.location.search)
    return (
        <ClientProfile id={params.id} destination={values.destination ? values.destination : null}/>
    )
  }
}

export default ClientPage