import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Menu, Dropdown, Button } from "antd";
import { API } from "../config";
import { PROCEDURES_QUERY } from "../graphql/query";

const ExportClientButton = ({ token, search }) => {
  const { data, loading } = useQuery(PROCEDURES_QUERY);
  const procedures = useMemo(() => {
    if (data) {
      return data.procedures;
    }
    return [];
  }, [data]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // set Health test as default
    setSelectedProcedures(
      procedures
        .filter((procedure) => procedure.name === "Health test")
        .map((procedure) => procedure.id),
    );
  }, [procedures]);
  return (
    <div>
      <Dropdown
        visible={open}
        overlay={
          <Menu>
            {procedures.map((procedure) => (
              <Menu.Item
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
                key={procedure.id}
                onClick={() => {
                  // add or remove procedure id from selectedProcedures
                  if (selectedProcedures.includes(procedure.id)) {
                    setSelectedProcedures(
                      selectedProcedures.filter((id) => id !== procedure.id),
                    );
                  } else {
                    setSelectedProcedures([
                      ...selectedProcedures,
                      procedure.id,
                    ]);
                  }
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedProcedures.includes(procedure.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedProcedures([
                        ...selectedProcedures,
                        procedure.id,
                      ]);
                    } else {
                      setSelectedProcedures(
                        selectedProcedures.filter((id) => id !== procedure.id),
                      );
                    }
                  }}
                />
                {procedure.name}
              </Menu.Item>
            ))}
            <div
              style={{
                padding: 10,
              }}
            >
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  const url = `${API}/export/${token}?search=${search}&procedures=${selectedProcedures.join(
                    ",",
                  )}`;
                  console.log(url);
                  window.open(url);
                }}
              >
                Export
              </Button>
            </div>
          </Menu>
        }
      >
        <Button
          onClick={() => {
            setOpen(!open);
          }}
          loading={loading}
          icon={"export"}
          style={{
            marginLeft: 10,
          }}
        >
          Select procedures to export
        </Button>
      </Dropdown>
    </div>
  );
};

export default ExportClientButton;
