import React, {Component} from 'react'
import {gql} from 'apollo-boost'
import {Query} from '@apollo/react-components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Descriptions, Tag, Button} from 'antd'
import history from '../history'
import UserEmailEditable from './UserEmailEditable'
import {PREFIX} from "../config";

const Container = styled.div`
.page-title{
    text-align:center;
    font-weight:bold;
}
`
const Q = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            firstName
            lastName
            username
            email
            phone
            isPasswordExpired
            address
            lastAccessed
            status
            enabledProcedures {
                procedureId
                name
                isEnabled
                createdAt
            }
            client {
                id
                caregiverRegistrationNumber
                gender
                dateOfBirth
                heathNotes
                preferredBreathRate
                audioType
                audioGuide
                audioFile
                disclaimerAccepted
                lastTested
            }
            caregiver{
                id
                businessName
                shareCode
            }
        }
    }
`

class CaregiverProfile extends Component {
  render() {
    const {id} = this.props
    return (
        <Query query={Q} variables={{id: id}}>
          {
            ({data, error, loading, client}) => {
              if (error) return <div>{error.toString()}</div>
              if (loading) return <div>Loading...</div>
              const user = data.user
              return (
                  <Container>
                    <h2 className={`page-title`}>Caregiver Profile</h2>
                    <Descriptions column={2}>
                      <Descriptions.Item label="Full name">
                        {`${user.firstName} ${user.lastName}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact email">
                        <UserEmailEditable
                            onChange={(value) => {

                              const variable = {
                                id,
                              }
                              const cache = client.readQuery({
                                query: Q,
                                variables: variable,
                              })

                              client.writeQuery({
                                query: Q,
                                variables: variable,
                                data: {
                                  ...cache,
                                  user: {
                                    ...cache.user,
                                    email: value,
                                  },

                                },
                              })
                            }}
                            userId={user.id}
                            defaultValue={user.email}/>
                      </Descriptions.Item>
                      <Descriptions.Item label="Business name">
                        {user.caregiver.businessName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact phone">
                        {user.phone}
                      </Descriptions.Item>
                      <Descriptions.Item label="User name">
                        {user.username}
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact address">
                        {user.address}
                      </Descriptions.Item>
                      <Descriptions.Item label="Account status">
                        {user.status}
                      </Descriptions.Item>
                      <Descriptions.Item label="Password status">
                        {
                          user.isPasswordExpired ? 'Expired' : 'Not expired'
                        }
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Enabled procedures">
                        {
                          user.enabledProcedures.map((pro, index) => {
                            if (pro.isEnabled) {
                              return (
                                  <span
                                      key={index}
                                      style={{marginRight: 5}}>
                                      <Tag>{pro.name}</Tag>
                                  </span>
                              )
                            }
                            return null
                          })
                        }
                      </Descriptions.Item>
                    </Descriptions>
                    <Button onClick={
                      () => {
                        history.push(`${PREFIX}/caregivers`)
                      }
                    }>Back</Button>
                  </Container>
              )
            }
          }
        </Query>
    )
  }

}

CaregiverProfile.propTypes = {
  id: PropTypes.any,
}
export default CaregiverProfile