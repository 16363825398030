import React from "react";
import { Button, Popconfirm, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const DELETE_CAREGIVER_MUTATION = gql`
  mutation deleteCaregiver($id: ID!) {
    deleteCaregiver(id: $id)
  }
`;

const DeleteCaregiverButton = ({ id, size = "small", onDeleteSuccess }) => {
  const [deleteUser, { loading }] = useMutation(DELETE_CAREGIVER_MUTATION);
  const confirm = () => {
    deleteUser({
      variables: { id },
    })
      .then(() => {
        message.success("Caregiver has been deleted");
        onDeleteSuccess();
      })
      .catch((err) => {
        message.error("An error!");
      });
  };
  return (
    <Popconfirm
      title={
        <div>
          <p>Are you sure delete this caregiver?</p>
        </div>
      }
      onConfirm={confirm}
      onCancel={() => {}}
      okText="Yes"
      cancelText="No"
      okType={"danger"}
    >
      <Button loading={loading} type={"danger"} size={size}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default DeleteCaregiverButton;
