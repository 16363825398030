import React, { Component } from 'react';
import CaregiverProfile from '../components/CaregiverProfile';

class CaregiverPage extends Component {
    render() {
        const {match:{params}}=this.props
        return (
         <CaregiverProfile id={params.id}/>
        );
    }
}

export default CaregiverPage;