import React, { Component } from "react";
import AcceptCaregiverInvite from "../../components/client/AcceptCaregiverInvite";

class AcceptInvitePage extends Component {
  render() {
    const { match } = this.props;
    const { token } = match.params;
    return <AcceptCaregiverInvite token={token} />;
  }
}

export default AcceptInvitePage;
