import React, {Component} from 'react'
import AppHeader from '../components/AppHeader'
import {Layout, Menu} from 'antd'
import {NavLink} from 'react-router-dom'
import {AppContext} from '../context'
import {PREFIX} from "../config";
import {ROLES} from "../helper";

const {Header, Content, Sider} = Layout

class MainLayout extends Component {
  state = {
    collapsed: false,
  }

  render() {
    const {isAnonymous, isHideSidebar} = this.props
    const links = [
      {
        title: 'Dashboard',
        to: `${PREFIX}/`,
        exact: true,
        roles: [ROLES.Administrator, ROLES.Observer]
      },
      {
        title: 'Clients',
        to: `${PREFIX}/clients`,
        roles: [ROLES.Administrator, ROLES.Observer]
      },
      {
        title: 'Caregivers',
        to: `${PREFIX}/caregivers`,
        roles: [ROLES.Administrator, ROLES.Observer]
      },
      {
        title: 'Breath guides',
        to: `${PREFIX}/breath-guides`,
        roles: [ROLES.Administrator]
      },
      {
        title: 'Procedure data',
        to: `${PREFIX}/procedure-data`,
        roles: [ROLES.Administrator, ROLES.Observer]
      },
    ]
    return (
      <Layout style={{background: '#FFF'}}>
        <Header style={{
          background: '#fff',
          padding: 0,
        }}>
          <AppHeader isAnonymous={isAnonymous}/>
        </Header>
        <Layout
          className={'app-main-layout'}
          style={{marginTop: 10, background: '#FFF'}}>
          {!isHideSidebar && (<AppContext.Consumer>
              {({currentUser}) => (
                currentUser && !isAnonymous ? (
                  <Sider trigger={null} style={{
                    background: '#FFF',
                    padding: 24,
                    margin: '24px 16px',
                  }}>
                    <Menu selectable={true} id={'main-menu'}>
                      {
                        links.map((link, key) => {
                          const currentUserRoles = currentUser.roles || []
                          const isAllowed = link.roles.some(role => currentUserRoles.includes(role))
                          if (!isAllowed) {
                            return null
                          }
                          return (<Menu.Item key={`${key}`}>
                            <NavLink
                              exact={link.exact}
                              to={link.to}>{link.title}
                            </NavLink>
                          </Menu.Item>)
                        })
                      }
                    </Menu>
                  </Sider>
                ) : null
              )}
            </AppContext.Consumer>
          )}
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: this.props.contentBackground,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
