import React, { Component } from 'react';
import {Button,Modal, notification} from 'antd'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'
import PropTypes from 'prop-types'

const MUTATION=gql`
    mutation deleteGuide($id:ID!){
        deleteGuide(id:$id)
        }
`

class DeleteBreathGuide extends Component {
    state={
        showModal:false,
        loadding:false
    }
    render() {
        const {data}=this.props
        return (
            <ApolloConsumer>
                {
                    (client)=>(
                        <React.Fragment>
                            <Button 
                             onClick={()=>{
                                this.setState({
                                    showModal:true
                                })
                            }}
                            type={'danger'} 
                            size={'small'}
                            >Delete</Button>
                            <Modal 
                                onOk={
                                    ()=>{
                                        this.setState({
                                            loading:true
                                        },
                                        ()=>{
                                            client.mutate(
                                                {
                                                    mutation:MUTATION,
                                                    variables:{
                                                        id:data.id
                                                    }
                                                }
                                            ).then(
                                                ()=>{
                                                    this.setState({
                                                        showModal:false,
                                                        loading:false
                                                    },
                                                    ()=>{
                                                        if(this.props.onCompleted){
                                                            this.props.onCompleted(client)
                                                        }
                                                    }
                                                    )
                                                }
                                            ).catch((e)=>{
                                                this.setState({
                                                    loading:false,
                                                    showModal:false
                                                })
                                                notification.error({message:e.toString()})
                                            })
                                        })
                                    }
                                }
                                okButtonProps={{loading:this.state.loading,type:'danger'}}
                                okText={'Delete'}
                                title ="Delete breath guide confirmation"
                                visible={this.state.showModal}
                                onCancel={()=>{
                                    this.setState({
                                        showModal:false
                                    })
                                }}
                            >
                                <p>Are you sure want to delete <strong>{`${data.name}`}</strong> ?</p>
                            </Modal>
                        </React.Fragment>
                    )
                }
            </ApolloConsumer>
  
        );
    }
}
DeleteBreathGuide.propTypes = {
    data: PropTypes.object,
    onCompleted: PropTypes.func,
  }
export default DeleteBreathGuide;