import React, { Component } from "react";
import ProcedureData from "../components/ProcedureData";

class ProcedureDataPage extends Component {
  render() {
    return <ProcedureData />;
  }
}

export default ProcedureDataPage;
