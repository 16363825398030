import React, {Component} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {PREFIX} from "../config";
import EnableAutomaticRegistrationAutoApproval from "../components/EnableAutomaticRegistrationAutoApproval";

const Container = styled.div`
.links{
  .link{
    margin-bottom: 15px;
  }
}
`

class HomePage extends Component {
  render() {

    const links = [
      {
        title: 'Manage clients',
        to: `${PREFIX}/clients`,
      },
      {
        title: 'Manage caregivers',
        to: `${PREFIX}/caregivers`,
      },
      {
        title: 'Manage breath guides',
        to: `${PREFIX}/breath-guides`,
      },
      {
        title: 'Procedure data',
        to: `${PREFIX}/procedure-data`,
      },
    ]
    return (
        <Container>
          <h2 className={'page-title'}>Admin Dashboard</h2>

          <div className={'links'}>
            {links.map((link, index) => (
                <div key={index} className={'link'}>
                  <Link to={link.to}>{link.title}</Link>
                </div>
            ))}
          </div>
          <EnableAutomaticRegistrationAutoApproval/>
        </Container>
    )
  }
}

export default HomePage
