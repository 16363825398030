import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Tag, Modal, notification} from 'antd'
import {gql} from 'apollo-boost'
import {Mutation} from '@apollo/react-components'

const MUTATION = gql`
    mutation updateUser($input: UpdateUser!) {
        updateUser(
            input: $input
        ) {
            isPasswordExpired
        }
    }
`

class ChangePasswordExpired extends Component {
  state = {
    showModal: false,
    loading: false,
  }

  render() {
    const {user} = this.props

    return (
        <React.Fragment>
          <Tag style={!user.isPasswordExpired ? {cursor: 'pointer'} : null} onClick={() => {
            if (!user.isPasswordExpired) {
              this.setState({
                showModal: true,
              })
            }
          }}
               color={user.isPasswordExpired ?
                   'volcano' :
                   'gray'}>{user.isPasswordExpired ?
              'Expired' :
              'Not expired'}</Tag>

          <Mutation
              onError={(e) => {
                notification.error({
                  message: e.toLocaleString().
                      replace('Error: GraphQL error:', ''),
                })
                this.setState({loading: false})
              }}
              onCompleted={() => {
                this.setState({
                  loading: false,
                  showModal: false,
                })
              }}
              mutation={MUTATION}>
            {(updateUser, {client}) => (
                <Modal
                    okButtonProps={{loading: this.state.loading}}
                    onOk={() => {
                      updateUser({
                        variables: {
                          input: {
                            id: user.id,
                            isPasswordExpired: true,
                          },
                        },
                      }).then(() => {

                        try {
                          const cache = client.readQuery({
                            query: this.props.query,
                            variables: this.props.variables,
                          })

                          let nodes = []
                          let updateKey = 'clients'

                          if (this.props.isCaregiver) {
                            nodes = cache.caregivers.nodes
                            updateKey = 'caregivers'
                          } else {
                            nodes = cache.clients.nodes
                          }

                          nodes = nodes.map((u) => {
                            if (u.id === user.id) {
                              return {...u, isPasswordExpired: true}
                            }
                            return u

                          })
                          client.writeQuery({
                            query: this.props.query,
                            variables: this.props.variables,
                            data: {
                              ...cache,
                              [updateKey]: {
                                ...this.props.isCaregiver ?
                                    cache.caregivers :
                                    cache.clients,
                                nodes: nodes,
                              },
                            },
                          })

                        } catch (e) {
                          console.log('err', e)
                        }

                      })
                    }}
                    onCancel={() => {
                      this.setState({
                        showModal: false,
                      })
                    }}
                    visible={this.state.showModal}
                    okText={'Yes'}
                    cancelText={'No'}
                    title={'Fore password expiration'}>
                  <p>
                    Are you sure to expire the password
                    for <strong>{`${user.firstName} ${user.lastName}`}</strong>
                  </p>
                </Modal>
            )}
          </Mutation>
        </React.Fragment>
    )
  }
}

ChangePasswordExpired.propTypes = {
  user: PropTypes.object,
  query: PropTypes.any,
  variables: PropTypes.object,
  isCaregiver: PropTypes.bool,
}

export default ChangePasswordExpired
