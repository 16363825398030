import React, { Component } from "react";
import PasswordResetForm from "../components/PasswordResetForm";

class PasswordResetsPage extends Component {
  render() {
    const { token } = this.props.match.params;
    return <PasswordResetForm token={token} />;
  }
}

export default PasswordResetsPage;
