import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, Form, Input, notification} from 'antd'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'

const MUTATION = gql`mutation updateProcedureData($input: UpdateData!){
    updateProcedureData(input: $input){
        procedureComment
    }
}`

class EditProcedureDataButton extends Component {
  state = {
    isToggle: false,
    loading: false,
  }

  render() {
    const {getFieldDecorator} = this.props.form
    return (
        <ApolloConsumer>
          {(client) => (
              <React.Fragment>
                <Button onClick={() => {
                  this.setState({isToggle: true})
                }} size={'small'}>Edit</Button>
                <Modal
                    okText={'Save'}
                    okButtonProps={{loading: this.state.loading}}
                    onOk={() => {
                      this.props.form.validateFields((err, values) => {
                        if (!err) {

                          this.setState({
                            loading: true,
                          }, () => {
                            client.mutate({
                              mutation: MUTATION,
                              variables: {
                                input: {
                                  id: this.props.data.id,
                                  procedureComment: values.procedureComment,
                                },
                              },
                            }).then(() => {
                              this.setState({
                                loading: false,
                                isToggle: false,
                              }, () => {
                                if (this.props.onCompleted) {
                                  const data = {
                                    ...this.props.data,
                                    procedureComment: values.procedureComment,
                                  }
                                  console.log('log data',data)
                                  this.props.onCompleted(client, data)
                                }
                              })
                            }).catch((e) => {
                              this.setState({
                                loading: false,
                              })
                              notification.error({message: e.toString()})
                            })
                          })
                        }
                      })
                    }}
                    title={'Edit procedure data'}
                    onCancel={() => {
                      this.setState({isToggle: false})
                    }}
                    visible={this.state.isToggle}>
                  <Form onSubmit={(e) => {
                    e.preventDefault()
                  }}>

                    <Form.Item label={'Procedure comment'}>
                      {
                        getFieldDecorator('procedureComment', {
                          initialValue: this.props.data.procedureComment,
                          rules: [
                            {
                              required: true,
                              message: 'Procedure comment is required',
                            },
                          ],
                        })(<Input.TextArea/>)
                      }
                    </Form.Item>
                  </Form>
                </Modal>
              </React.Fragment>
          )}
        </ApolloConsumer>
    )
  }
}

EditProcedureDataButton.propTypes = {
  data: PropTypes.object,
  onCompleted: PropTypes.func,
}

export default Form.create({name: 'form'})(EditProcedureDataButton)
