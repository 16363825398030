import React, {Component} from 'react'
import EditProfileForm from '../components/EditProfileForm'
import {AppContext} from '../context'

class EditProfilePage extends Component {
  render() {
    return (
        <AppContext.Consumer>
          {({currentUser, setCurrentUser}) => (
              <EditProfileForm onCompleted={(user) => {
                setCurrentUser(user)
              }} currentUser={currentUser}/>
          )}
        </AppContext.Consumer>
    )
  }
}

export default EditProfilePage