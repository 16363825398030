import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import ClientsPage from "./pages/Clients";
import ClientPage from "./pages/Client";
import Caregivers from "./pages/Caregivers";
import RegisterPage from "./pages/client/Register";
import AcceptInvitePage from "./pages/client/AcceptInvite";
import CaregiverPage from "./pages/Caregiver";
import ForgotPasswordPage from "./pages/ForgotPassword";
import PasswordResetsPage from "./pages/PasswordResets";
import EditProfilePage from "./pages/EditProfile";
import BreathGuidesPage from "./pages/BreathGuides";
import ProcedureDataPage from "./pages/ProcedureData";

export const routes = [
  {
    path: "/",
    exact: true,
    component: HomePage,
    useLayout: true
  },
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    useLayout: true,
    background: "none",
    anonymous: true
  },
  {
    path: "/profile/edit",
    exact: true,
    component: EditProfilePage,
    useLayout: true,
    hideSidebar: true,
    background: "none"
  },
  {
    path: "/clients",
    exact: true,
    component: ClientsPage,
    useLayout: true
  },
  {
    path: "/clients/:id",
    exact: true,
    component: ClientPage,
    useLayout: true
  },
  {
    path: "/caregivers",
    exact: true,
    component: Caregivers,
    useLayout: true
  },
  {
    path: "/caregivers/:id",
    exact: true,
    component: CaregiverPage,
    useLayout: true
  },
  {
    path: "/client/register/:token",
    exact: true,
    component: RegisterPage,
    useLayout: true,
    anonymous: true,
    background: "none"
  },
  {
    path: "/client/accept-invite/:token",
    exact: true,
    component: AcceptInvitePage,
    useLayout: true,
    anonymous: true,
    background: "none"
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPasswordPage,
    useLayout: true,
    anonymous: true,
    background: "none"
  },
  {
    path: "/password-resets/:token",
    exact: true,
    component: PasswordResetsPage,
    useLayout: true,
    anonymous: true,
    background: "none"
  },
  {
    path: "/breath-guides",
    exact: true,
    component: BreathGuidesPage,
    useLayout: true
  },
  {
    path: "/procedure-data",
    exact: true,
    component: ProcedureDataPage,
    useLayout: true
  }
];
